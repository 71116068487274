<template>
  <van-pull-refresh
    v-model="loading"
    :style="{
      height: scrollerHeight,
      overflow: 'auto',
    }"
    @refresh="onRefresh"
  >
    <div v-if="list.length > 0" class="appointmentList">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item"
        @click="toInfo(item)"
      >
        <div class="title">
          申请时间：{{ item.createTime }}
          <span class="''"><van-icon name="arrow" /></span>
        </div>
        <div class="list">
          <div class="grid-table">
            <div>患者姓名</div>
            <div>{{ item.name }}</div>
            <div>费用</div>
            <div>{{ item.copyFee }}元</div>
            <div>审批状态</div>
            <div>{{ item.statusText }}</div>
            <div v-if="item.status === 1">支付状态</div>
            <div v-if="item.status === 1">{{ item.payStateText }}</div>
            <div>审批时间</div>
            <div>{{ item.statusTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-else description="暂无复印申请信息，请重新查询" />
  </van-pull-refresh>
</template>

<script>
  import store from '@/store'
  import moment from 'moment/moment'
  import { Toast, Dialog } from 'vant'
  import { getList } from '@/api/case/caseCopy'
  import Base64 from '@/util/Base64'
  export default {
    name: 'CaseCopyList',
    data() {
      return {
        loading: false,
        userInfo: null,
        list: [],
        search: {
          openID: '',
        },
      }
    },
    computed: {
      scrollerHeight: function() {
        return window.innerHeight + 'px'
      },
    },
    created() {
      this.search.openID = store.getters['user/openId']
      this.fetchData()
    },
    methods: {
      //下拉刷新
      onRefresh() {
        this.fetchData()
      },

      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '加载中...',
          forbidClick: true,
        })
        const { data } = await getList(this.search, {
          strOrderByFields: 'ID DESC',
        })
        this.list = data
        Toast.clear()
        this.loading = false
      },

      //跳转详情
      toInfo(item) {
        this.$router.push({
          path: '/caseCopyInfo',
          query: {
            id: Base64.encode(item.id),
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .van-tabs {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
  }
  .appointmentList {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    font-family: 'Microsoft Yahei';
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px;
    }
  }

  .grid-table {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    div:nth-child(odd) {
      color: #9e9e9e;
      font-size: 0.4rem;
    }
    .dept {
      color: #646464;
      font-size: 0.4rem;
    }
  }
</style>
